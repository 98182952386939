<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img
      src="https://seeklogo.com/images/X/xtc-indonesia-logo-CABFEB3175-seeklogo.com.png"
      alt="Logo Taekwondo Indonesia"
      width="150"
      class="mt-1"
    />
    <br />
    <img
      :src="
        'https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=https://form.xtcindonesia.org/authenticity/' +
        qr_code
      "
      width="120"
      alt=""
      srcset=""
      class="mt-3"
    />
    <h4 class="mt-4">{{ qr_code }}</h4>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qr_code: this.$route.query.id,
    };
  },
};
</script>

<style lang="scss" scoped></style>
